import { mapState, mapGetters, mapActions } from "vuex";

export const authComputed = {
    ...mapState("auth", {
        currentUser: (state) => state.currentUser,
    }),
    ...mapGetters("auth", ["loggedIn"]),
};

export const layoutComputed = {
    ...mapState("layout", {
        layoutType: (state) => state.layoutType,
        leftSidebarType: (state) => state.leftSidebarType,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        loader: (state) => state.loader,
    }),
};

export const adminMethods = mapActions("admin", [
    "loadCoreData",
    "setCoreLoaded",
    "setSelectedBranch",
    "getCompanyUsers",
    "setCompanyUsers",
    "setUserRoles",
    "createUser",
    "handleLogout",
    "deleteCompanyUser",
    "setSelectedUserToEdit",
    "updateUser",
]);

export const adminComputed = {
    ...mapState("admin", {
        core: (state) => state.core,
        core_loaded: (state) => state.core_loaded,
        is_core_loading: (state) => state.is_core_loading,
        selected_branch_id: (state) => state.selected_branch_id,
        company_users: (state) => state.company_users,
        user_roles: (state) => state.user_roles,
        loggedIn_user_role: (state) => state.loggedIn_user_role,
        loggedIn_user_id: (state) => state.loggedIn_user_id,
        user_id: (state) => state.user_id,
        user_role: (state) => state.user_role,
        user_client_id: (state) => state.user_client_id,
        selected_userToEdit: (state) => state.selected_userToEdit,
    }),
    ...mapGetters("admin", ["getUserId"]),
};

export const clientMethods = mapActions("clients", [
    "getClients",
    "saveClient",
    "deleteClient",
    "setSelectedClientId",
    "setSelectedClientDetails",
    "setClientUsers",
    "createClientUser",
    "deleteClientOrder",
    "setFilteredSelectedClientDetails",
    "setSelectedClientUserToEdit",
    "updateClientUser",
    "deleteClientUser",
    "deleteClient",
    "saveProfileQuestionAnswers"
]);

export const clientComputed = {
    ...mapState("clients", {
        clients: (state) => state.clients,
        selected_client_id: (state) => state.selected_client_id,
        selected_client_details: (state) => state.selected_client_details,
        client_users: (state) => state.client_users,
        selectedFiltered_client_details: (state) => state.selectedFiltered_client_details,
        selected_client_orders: (state) => state.selected_client_orders,
        selected_client_userToEdit: (state) => state.selected_client_userToEdit,
    }),
};

export const productMethods = mapActions("products", [
    "getProducts",
    "getProductDetails",
    "saveProduct",
    "deleteProduct",
    "setSelectedProduct",
    "getProperties",
    "saveProductPropertyValue",
    "saveProductProperty",
    "deleteProductProperty",
    "setAllProductGroups",
    "setAllproductLoadingStatus",
    "setAllProductGroupsLoadingStatus",
    "saveProductGroup",
    "setSelctedProductGroupDetails",
    "deleteProductGroup",
    "addOrRemoveProductsToSaveToGroup",
    "addToProductsToSaveToGroup",
    "removeFromProductsToSaveToGroup",
    "setAllLinkedProductsByGroup",
    "setAllLinkedProductsLoadingStatus",
    "addRolesToSaveToGroup",
    "removeFromRolesToSaveToGroup",
    "setSelectedRoleForGroupDetails",
    "saveProductGroupRolePricing",
    "setAllDisplayMaterialProducts",
    "deleteGroupPricing",
]);

export const productComputed = {
    ...mapState("products", {
        all_products: (state) => state.all_products,
        selected_product_id: (state) => state.selected_product_id,
        selected_product_details: (state) => state.selected_product_details,
        all_properties: (state) => state.all_properties,
        selected_propertyid: (state) => state.selected_property_id,
        all_product_groups: (state) => state.all_product_groups,
        all_products_loading_status: (state) => state.all_products_loading_status,
        all_products_groups_loading_status: (state) => state.all_products_groups_loading_status,
        selected_product_group_details: (state) => state.selected_product_group_details,
        productIds_to_save_to_group: (state) => state.productIds_to_save_to_group,
        all_linked_products: (state) => state.all_linked_products,
        all_linked_products_loading_status: (state) => state.all_linked_products_loading_status,
        all_linked_roles: (state) => state.all_linked_roles,
        roleIds_to_save_to_group: (state) => state.roleIds_to_save_to_group,
        active_linked_roles: (state) => state.active_linked_roles,
        selected_role_for_group_details: (state) => state.selected_role_for_group_details,
        role_pricing: (state) => state.role_pricing,
        all_display_material_products: (state) => state.all_display_material_products,
        group_pricing_roles: (state) => state.group_pricing_roles,
    }),
};

export const repMethods = mapActions("reps", [
    "setRepUsers",
    "setSelectedRepId",
    "setSelectedRepDetails",
    "setClients",
    "createRepToClientLink",
    "getSelectedRepDetails",
    "setFilteredSelectedRepDetails",
    "createRepClient",
    "createRepUser",
    "deleteRepUser",
]);

export const repComputed = {
    ...mapState("reps", {
        all_reps: (state) => state.all_reps,
        selected_rep_id: (state) => state.selected_rep_id,
        selected_rep_details: (state) => state.selected_rep_details,
        all_clients: (state) => state.all_clients,
        // selected_client_details: (state) => state.selected_client_details,
        selectedFiltered_rep_details: (state) => state.selectedFiltered_rep_details,
        selected_rep_clients: (state) => state.selected_rep_clients,
    }),
};

export const orderMethods = mapActions("orders", [
    "getBranchOrders",
    "getOrderDetails",
    "createOrder",
    "deleteOrder",
    "setSelectedOrder",
    "getOrderProperties",
    "getTokenOrderDetails",
    "updateOrder",
    "updateExternalOrderStatus",
    "setBranchOrdersLoadingStatus",
    "saveEmailTemplate",
]);

export const orderComputed = {
    ...mapState("orders", {
        branch_orders: (state) => state.branch_orders,
        order_properties: (state) => state.order_properties,
        selected_order_id: (state) => state.selected_order_id,
        selected_order_details: (state) => state.selected_order_details,
        ext_order: (state) => state.ext_order,
        is_order_readonly: (state) => state.is_order_readonly,
        statsOverview: (state) => state.statsOverview,
        branch_orders_loading_status: (state) => state.branch_orders_loading_status,
    }),
};

export const crmMethods = mapActions("crm", [
    "setAllSalesLeads",
    "deleteSalesLead",
    "saveIncomingSalesLead",
    "setMarketingBanners",
    "setMarketingBannersLoadingStatus",
    "deleteMarketingBanner",
    "setEmailTemplates",
    "saveEmailTemplate",
    "setEmailTemplatesLoadingStatus",
    "setSelectedTemplateDetails",
    "setAllMailingLists",
    "setAllMailingListsLoadingStatus",
    "setSelectedMailingList",
    "setSelectedMailingListLoadingStatus",
    "saveMailingList",
    "setSelectedMailingListDetails",
    "deleteEmailTemplate",
    "addClientIdsToSaveToMailingList",
    "removeClientIdsToSaveToMailingList",
    "saveMailingListClients",
    "deleteMailingList",
    "sendMailingList",
    'completeMembership',
    'getMembershipCompletionData',
    'finaliseMembership',
    'saveNewMember'
]);

export const crmComputed = {
    ...mapState("crm", {
        all_sales_leads: (state) => state.all_sales_leads,
        all_sales_leads_loading_satus: (state) => state.all_sales_leads_loading_satus,
        marketing_banners: (state) => state.marketing_banners,
        marketing_banners_loading_status: (state) => state.marketing_banners_loading_status,
        email_templates: (state) => state.email_templates,
        email_templates_loading_status: (state) => state.email_templates_loading_status,
        selected_template_details: (state) => state.selected_template_details,
        all_mailing_lists: (state) => state.all_mailing_lists,
        all_mailing_lists_loading_status: (state) => state.all_mailing_lists_loading_status,
        selected_mailing_list: (state) => state.selected_mailing_list,
        selected_mailing_list_loading_status: (state) => state.selected_mailing_list_loading_status,
        selected_mailing_list_details: (state) => state.selected_mailing_list_details,
        clientids_to_save_to_mailing_list: (state) => state.clientids_to_save_to_mailing_list,
        completion_data: (state) => state.completion_data
    }),
};

export const authMethods = mapActions("auth", ["logIn", "logOut"]);

export const layoutMethods = mapActions("layout", [
    "changeLayoutType",
    "changeLayoutWidth",
    "changeLeftSidebarType",
    "changeTopbar",
    "changeLoaderValue",
]);

//export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions("notification", ["success", "error", "clear"]);
